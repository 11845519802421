import React from 'react'
import { ThemeToggle } from './ThemeToggle'

import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

const HomeNav = (props) =>{
  return(
    <div className='w-1/2'>
      <div className='nav flex justify-evenly items-center text-xs min-[500px]:text-sm sm:text-2xl'>
            
        <NavLink to="/" className={({ isActive }) => isActive ? 'border-b-2 sm:border-b-4': 'border-b-0'}>
          Home
        </NavLink> 
      
        <NavLink to="/about" className={({ isActive }) => isActive ? 'border-b-2 sm:border-b-4': 'border-b-0'}>
          About
        </NavLink> 
      
        <ThemeToggle />
          
      </div>
    </div>
  )
}

const ProjNav = (props) =>{
  return(
    <div className='w-1/2'>
      <div className='nav flex justify-evenly items-center text-xs min-[500px]:text-sm sm:text-2xl'>
            
        <NavLink to="/projects" className={({ isActive }) => isActive ? 'border-b-2 sm:border-b-4': 'border-b-0'}>
          Projects
        </NavLink> 
      
        <ThemeToggle />
          
      </div>
    </div>
  )
}

export const Navbar = () => {
  return (
    <div className='fixed w-full h-auto flex items-center px-6 sm:px-20 py-4 z-50 mb-16 bg-stone-600 dark:bg-stone-800 text-white'>
        
      <div className='w-1/2 font-bold text-sm sm:text-xl xl:text-3xl'>
        <NavLink className='w-1/2 font-bold text-sm sm:text-xl xl:text-3xl' to="/" >
          ALESSANDRO DAVID
        </NavLink> 
      </div>

      <HomeNav />
    

    </div>
  )
}
