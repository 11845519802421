import React from 'react'
import { Bubble, DescriptionCard } from '../Common'

export const Quote = () => {
    const quotes = [ 
        "I wish there was a way to know you're in the good old days before you've actually left them.",
        "It gets easier. Every day it gets a little easier. But you gotta do it every day — that's the hard part.",
        "It is possible to commit no mistakes and still lose. That is not a weakness, that is life.",
        "You are the average of the five people you spend the most time with."
    ]

    const sources = [ 
        "Andy Bernard - The Office.",
        "Jogging Baboon - BoJack Horseman.",
        "Captain Jean-Luc Picard - Star Trek.",
        "Jim Rohn."
    ]

    const randomIndex = Math.floor(Math.random() * quotes.length);

    return (
        <Bubble>
            <div className='h-full flex flex-col justify-between'>
                <DescriptionCard>Random</DescriptionCard>
                <div className='text-stone-800 sm:text-2xl dark:text-white'>
                    <div className='italic text-sm mt-2 sm:text-xl'>" {quotes[randomIndex]} "</div>
                    <div className='text-sm text-right mt-2 sm:text-xl'>{sources[randomIndex]}</div>
                </div>

                <div></div>
            </div>
        </Bubble>
  )
}
