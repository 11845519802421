import React from 'react'
import { Bubble, DescriptionCard } from '../Common'

export const Music = (props) => {
  const monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthName = monthNames[props.currentDate.getMonth()];
  const spotifyUrl= process.env.REACT_APP_SPOTIFY_URL

  return (
    <Bubble>
        <DescriptionCard>{monthName}'s most listened song:</DescriptionCard>
        <div className=''>
            <iframe title='spotify' className="w-full h-20 sm:h-60" src={spotifyUrl} frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
    </Bubble>
  )
}
