import React from 'react'
import { Bubble, ListSection } from '../Common'

const WishlistItem = ({nameItem, img, url}) =>{
    return (
        <div className='container flex flex-col'>
            <a href={url} target="_blank" className='flex'>
                <img src={img} className='bg-white p-2 sm:p-4 border-4 sm:border-8 border-stone-300 dark:border-stone-400 rounded-3xl min-w-full aspect-square object-contain' />
            </a>

            <div className='mt-2 sm:mt-4 text-xs text-center sm:text-lg dark:text-stone-200'>{nameItem}</div>
        </div>
    )
}

const CategoryItem = (props) =>{
    return(
        <Bubble>
            <div className='dark:text-white sm:text-2xl'>{props.category.name}</div>
            <div className='mt-2 sm:mt-4 grid grid-cols-3 justify-between justify-items-center gap-3'>
                {props.category.items.map((item, index) => (
                    <WishlistItem nameItem={item} img={props.category.imgs[index]} url={props.category.urls[index]} />
                ))}
            </div>
        </Bubble>
    )
}

export const WishList = () => {
    const title = "WISHLIST"
    const description = "To keep track of the things I need:"
    const techCategory = {
        name: "Tech",
        items: [
            "SSD SanDisk 1TB",
            "Camera Light",
            "Raspeberry Pi 5 (4GB)"
        ],
        imgs: [
            "https://m.media-amazon.com/images/I/712+jjz3lyL._AC_UL320_.jpg",
            "https://m.media-amazon.com/images/I/71oEBP72TjL._AC_UL320_.jpg",
            "https://m.media-amazon.com/images/I/61EQZoZvcEL._AC_UL320_.jpg"
        ],
        urls: [
            "https://www.amazon.it/SanDisk-portatile-scrittura-resistente-impermeabile/dp/B08GTYFC37",
            "https://www.amazon.it/ENEGON-2500K-9900K-Ricaricabile-Professionali-Illuminazione/dp/B08K7H42D7",
            "https://www.kubii.com/it/nano-computer/4106-1831-raspberry-pi-5-3272496315938.html"
        ]
    }
    const travelCategory = {
        name: "Travel",
        items: [
            "Neck Pillow",
            "Hydration Bladder",
            "OLIGHT Arkfeld"
        ],
        imgs: [
            "https://m.media-amazon.com/images/I/813M2DvlybL._AC_UL320_.jpg",
            "https://m.media-amazon.com/images/I/61GzlMx31UL._AC_UL320_.jpg",
            "https://m.media-amazon.com/images/I/614jv7xk2oL._AC_UL320_.jpg"
        ],
        urls: [
            "https://www.amazon.it/SOMLAW-Cervicale-Poggiatesta-Maschera-Orecchie/dp/B0B96TP1WX/ref=sr_1_9",
            "https://www.decathlon.it/p/sacca-d-acqua-mtb-2l-azzurra-trasparente/_/R-p-313036",
            "https://www.amazon.it/Olight-Ricaricabile-Combinata-Funzionale-Situazioni/dp/B0BPXTWJPV"
        ]
    }
    const categories =[ techCategory, travelCategory]

    return (
    <ListSection title={title} description={description}>  
        {categories.map((item, index) => (<CategoryItem category={categories[index]}/>))}
    </ListSection>
  )
}
