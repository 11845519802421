import React from 'react'

import { Villain } from "./subcomponents/Villain";
import { Latest } from "./subcomponents/Latest";
import { Statistics } from './subcomponents/Statistics';
import { Social } from './subcomponents/Social';
import { Dance } from "./subcomponents/Dance";
import { WishList } from "./subcomponents/WishList";

export const AboutPage = () => {
  const currentDate = new Date()
  return (
    <div>
        <Villain currentDate={currentDate}/>
        {/*<Latest />*/}
        <Statistics currentDate={currentDate}/>
        <Social />
        <Dance />
        <WishList />
    </div>
  )
}
