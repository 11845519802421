import React from "react"
import { ListSection } from '../Common'
import project1 from '../../assets/p1.png' 
import project2 from '../../assets/p2.png' 

const ProjectItem = ({title, description, photo, url, open_tab}) =>{
    // bg-gradient-to-br from-[#833ab4] via-[#fd1d1d] to-[#fcb045]
    return(
        <div className='flex flex-col'>
            <div className='h-full rounded-xl sm:rounded-2xl px-4 sm:px-6 py-2 sm:py-4 bg-stone-200 dark:bg-stone-400'>
                <div className='font-bold sm:text-3xl dark:text-white'>{title}</div>

                <div className='pt-2 flex'>

                    <div className='size-1/3 flex aspect-square items-center bg-stone-100 justify-center rounded-xl'>
                        <img className="object-cover rounded-xl" src={photo} alt="PatchCore Transformed" />
                    </div>

                    <div className="w-2/3 pl-4">
                        <div className='text-xs sm:text-xl dark:text-white'>
                            {description}
                        </div>

                    </div>

                </div>

                <a target={open_tab ? "_blank" : "_self"} className="w-full flex justify-end text-xs text-white dark:text-stone-200 sm:text-xl italic" href={url}>
                    <div className="px-2 py-1 sm:px-4 sm:py-2 rounded-xl bg-stone-400 dark:bg-stone-600"> Learn more </div>
                </a>
                
            </div>

        </div>
    )
}

export const Projects = () => {
    const title = "PROJECTS"
    const description = "Some of the side projects that I worked on:"

    const project_titles = ['PatchCore Transformed', 'This Website']
    const project_descriptions = [
        "This project was part of my master's thesis. It focused on integrating ViTs into PatchCore as an alternative to traditional CNNs.",
        "The webpage that you're currently on is also a personal project of mine, created to help me learn how to build a website from scratch."
    ]
    
    const patchcoreUrl= process.env.REACT_APP_PATCHCORE_URL
    const websiteUrl= process.env.REACT_APP_WEBSITE_URL

    const project_urls = [
        patchcoreUrl,
        websiteUrl
    ]

    const open_tab = [true, false]

    const photos = [project1, project2]

    return (
        <ListSection title={title} description={description}>
            {project_titles.map((item, index) => (
                <ProjectItem title={item} description={project_descriptions[index]} photo={photos[index]} url={project_urls[index]} open_tab={open_tab[index]} />
            ))}
        </ListSection>
  )
}
