import React, { useEffect, useState } from 'react';
import { Bubble, InfoCircle, DescriptionCard } from '../Common'

// targetUrl = "https://fit-bit-api.alessandro-david2.workers.dev"
// response = await fetch(targetUrl)

const WorkoutItem = (props) => {
  return(
    <div className='flex justify-between px-4 sm:px-8'>{props.text}: <span className='font-bold'>{props.value}</span></div>
  )
}

export const Workout = () => {
  
  const [calories, setCalories] = useState(null);
  const [steps, setSteps] = useState(null);
  const [distance, setDistance] = useState(null)
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const targetUrl=process.env.REACT_APP_FITBIT_API
  
  useEffect(() => {
    const fetchCalories = async () => {
      try {
        const response = await fetch(targetUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        var result = await response.json();
        
        var calories = '💥' + result.split('#')[0] + '💥'
        var steps = '👟' + result.split('#')[1] + '👟'
        var distance = '📏' + parseFloat(result.split('#')[2]).toFixed(2) + '📏'

        setCalories(calories)
        setSteps(steps)
        setDistance(distance)
      } catch (error) {
        setError(error);
      } finally {
        console.log(result)
        setLoading(false);
      }
    };

    fetchCalories();
  }, []);

  var description = "Data is updated in real time."
  return (
    <Bubble>
        <div className='h-full flex flex-col justify-between'>
            <div className='flex justify-between'>
              <DescriptionCard>Today's Fitness Summary:</DescriptionCard>
              <InfoCircle description={description}/>
            </div>

            <div className='text-stone-800 sm:text-2xl dark:text-white'>
                {loading && <div className='text-xl font-bold text-center mt-2 sm:text-3xl'>Loading...</div>}
                {error && <div className='text-xl font-bold text-center mt-2 sm:text-3xl'>Error: {error.message}</div>}
                {calories && 
                <div className='text-center my-2 sm:text-3xl'>
                  <WorkoutItem text={'Calories burned'} value={calories} />
                  <WorkoutItem text={'Step counter'} value={steps} /> 
                  <WorkoutItem text={'Distance'} value={distance} />
                </div>}
                  
            </div>

            <div className='italic text-xs text-right  sm:text-xl text-stone-800 dark:text-stone-100'>Powered by FitBit</div>

        </div>
    </Bubble>
  )
}
