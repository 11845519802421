import React from 'react'
import photo from '../../assets/Z2.jpg'

const SocialButton = (props) =>{
  return(
    <a target="_blank" className={`text-xs sm:text-3xl text-white px-2 sm:px-4 py-1 sm:py-3 mt-2 sm:mt-8 rounded-xl sm:rounded-2xl ${props.color}`} href={props.link}>
      {props.name}
    </a>
  )
}

export const Hero = () => {
  return (
    <div id="hero" className='flex pt-16 sm:pt-24 pb-4 px-6 sm:px-20 bg-stone-100 dark:bg-stone-600 dark:text-white'>
        
        <div className='flex flex-col justify-center w-1/2 pr-2'> 
            <div className='font-extrabold text-lg sm:text-6xl'>
              Hello 👋🏽 ! I'm Ale.
            </div>
            <div className='text-xs sm:text-3xl dark:text-stone-200'>
              I graduated from the <span className='font-bold dark:text-white'>University of Bologna</span>, with a Bachelor's degree in <span className='font-bold dark:text-white'>Computer Engineering</span>.
              My passion for technology then inspired me to pursue a Master's degree in <span className='font-bold dark:text-white'>Artificial Intelligence</span>.
            </div>

            <div className='flex gap-2 sm:gap-4'>
              <SocialButton name='LinkedIn' color='bg-[#0077B5]' link="https://www.linkedin.com/in/alepower"/>
              <SocialButton name='GitHub' color='bg-[#24292e]'link="https://github.com/notalepower" />
            </div>

        </div>
        
        {/* PHOTO */}
        <div className='w-1/2 flex flex-col justify-center items-center p-2'>
          <div className='bg-opache sm:w-5/6'>
            <img src={photo} className='rounded-full min-w-full aspect-square object-cover' alt='Foto Profilo'/>
          </div>
        </div>

    </div>
  )
}
