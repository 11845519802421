import React from 'react'
import photo from '../../assets/Z1.jpg' 

export const Villain = (props) => {
  const timeDifference = props.currentDate - new Date('June 14, 2016');
  const streak = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const location= process.env.REACT_APP_LOCATION

  return (
    <div className='flex pt-16 mb-4 sm:pt-24 sm:px-20 pb-4 px-6 font-sans bg-stone-100 dark:bg-stone-600 dark:text-white'>
        {/* PHOTO */}
        <div className='w-1/2 flex flex-col justify-center pr-4'>
          <div className='bg-opache sm:w-5/6'>
            <img src={photo} className='rounded-full min-w-full aspect-square object-cover' alt='Foto Profilo'/>
          </div>
        </div>

        <div className='flex flex-col justify-center w-1/2 pl-4'> 
            <div className='font-extrabold text-lg sm:text-6xl'>
              Currently:
            </div>
            <div className='text-xs sm:text-3xl dark:text-stone-200'>
              <ul>
                <li>- In {location}. </li>
                <li>- In a D&amp;D campaign. </li>
                <li>- On a <span className='font-bold text-black dark:text-white'>{streak}</span> 🔥streak.</li>
                <li>- Watching Beast Games. </li>
                <li>- Applying for dual citizenship. </li>
              </ul>

            </div>
        </div>
        

    </div>
  )
}
